/**
 * Valida as dimensões de um conjunto de arquivos de imagem.
 * @param {File[]} filesArray - Array de arquivos de imagem para validar.
 * @param {number} width - Largura desejada das imagens.
 * @param {number} height - Altura desejada das imagens.
 * @returns {Promise<{valid: File[], invalid: File[]}>}
 */
const validateImageSizes = async (filesArray, width, height) => {
  const results = await Promise.all(filesArray.map((file) => new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      resolve(img.width === width && img.height === height ? { valid: file } : { invalid: file });
    };
    img.onerror = () => {
      resolve({ invalid: file, error: true });
    };
    img.src = URL.createObjectURL(file);
    img.onloadend = () => URL.revokeObjectURL(img.src);
  })));

  const validFiles = results.filter((result) => result.valid).map((result) => result.valid);
  const invalidFiles = results.filter((result) => result.invalid).map((result) => result.invalid);

  return { valid: validFiles, invalid: invalidFiles };
};

export default validateImageSizes;
