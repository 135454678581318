<template>
  <v-card>
    <v-toolbar dark color="primary">
      <v-btn icon @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Campanha</v-toolbar-title>
    </v-toolbar>

    <v-form ref="form">
      <v-container fluid>
        <v-row align="center" justify="start">
          <v-col cols="12">
            <v-checkbox v-model="campaign.active" label="Ativo?" />
          </v-col>

          <v-col cols="12" md="4" lg="3" xl="3">
            <v-text-field
              v-model="campaign.title"
              label="Título da Campanha *"
              :rules="[rules.required]"
              outlined
              required
              dense />
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" xl="3">
            <v-text-field
              v-model="campaign.link"
              label="Link da Campanha *"
              :rules="[rules.required]"
              outlined
              required
              dense />
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" xl="3">
            <v-text-field
              v-model="formattedInitialDate"
              v-mask="'##/##/####'"
              label="Data Início"
              :rules="[rules.required]"
              outlined
              required
              dense />
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" xl="3">
            <v-text-field
              v-model="formattedEndDate"
              v-mask="'##/##/####'"
              label="Data Fim"
              :rules="[rules.required]"
              outlined
              required
              dense />
          </v-col>

          <v-col cols="12" sm="6" md="5" lg="3" xl="3">
            <v-select
              v-model="campaign.targetProd"
              :items="targetProd"
              label="Produtos Alvos da Campanha *"
              item-text="title"
              return-object
              multiple
              chips
              outlined
              :rules="[rules.selectRequired]" />
          </v-col>

          <v-col cols="12" sm="12" md="12" lg="9" xl="9">
            <v-file-input
              v-model="imagesUpload"
              :images="imagesPreview"
              required
              label="Adicionar imagens do tamanho de 1024x768"
              prepend-icon="mdi-camera"
              outlined
              :rules="imageAlreadyExists ? [] : $rules.required"
              accept="image/jpeg, image/png"
              @change="onFilesSelected" />
          </v-col>

          <v-col cols="12" md="6" lg="6" xl="6">
            <v-textarea
              v-model="campaign.text"
              label="Texto da Campanha *"
              :rules="[rules.required]"
              outlined />
          </v-col>

          <v-col cols="12" md="6" lg="5" xl="5">
            <v-img
              v-for="(image, index) in imagesPreview"
              :key="index"
              :src="image"
              aspect-ratio="1.6" />
            <v-alert
              v-if="imageSizeError"
              type="error"
              border="left"
              elevation="2"
              icon="mdi-alert"
              class="mt-2">
              {{ imageSizeError }}
            </v-alert>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <v-card-actions class="justify-center py-10">
      <v-btn color="secondary" :disabled="isLoading" @click="close">Cancelar</v-btn>
      <v-btn color="primary" :disabled="isLoading" @click="submit">Salvar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mask } from 'vue-the-mask';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import CampaignService from '../../../services/campaign.service';
import TargetProductService from '../../../services/target-product.service';
import { createFeaturedPhoto } from '../../../services/photos-service';
import validateImageSizes from '../../../utils/image-validate-size';

dayjs.extend(customParseFormat);

export default {
  name: 'CampaignForm',
  directives: {
    mask,
  },
  props: {
    populateWith: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      campaign: {
        active: false,
        title: '',
        link: '',
        initialDate: '',
        endDate: '',
        text: '',
        targetProd: [],
      },
      targetProd: [],
      imagesPreview: [],
      imagesUpload: null,
      isLoading: false,
      imageSizeError: '',
    };
  },
  computed: {
    rules() {
      return {
        required: (value) => !!value || 'Este campo é obrigatório.',
        selectRequired: (value) => (value && value.length > 0) || 'Este campo é obrigatório.',
      };
    },
    formattedInitialDate: {
      get() {
        return this.campaign.initialDate ? dayjs(this.campaign.initialDate).format('DD/MM/YYYY') : '';
      },
      set(value) {
        this.campaign.initialDate = value ? dayjs(value, 'DD/MM/YYYY').toISOString() : null;
      },
    },
    formattedEndDate: {
      get() {
        return this.campaign.endDate ? dayjs(this.campaign.endDate).format('DD/MM/YYYY') : '';
      },
      set(value) {
        this.campaign.endDate = value ? dayjs(value, 'DD/MM/YYYY').toISOString() : null;
      },
    },
    imageAlreadyExists() {
      return Boolean(this.campaign?.imageUrl) || Boolean(this.imagesUpload);
    },
  },
  async created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      if (this.populateWith.id) {
        this.campaign = { ...this.populateWith };
        this.imagesPreview = this.campaign?.imageUrl
          ? [this.campaign?.imageUrl]
          : [];
      }
      this.targetProd = await TargetProductService.findAll();
    },

    close() {
      this.$emit('close-dialog');
    },

    async onFilesSelected(files) {
      this.imageSizeError = '';
      const filesArray = Array.isArray(files) ? files : [files];
      const { valid, invalid } = await validateImageSizes(filesArray, 1024, 768);
      this.imagesPreview = valid.map((file) => URL.createObjectURL(file));
      if (invalid.length > 0) {
        this.imageSizeError = 'A imagem não atende aos requisitos de tamanho de 1024x768.';
      }
    },

    async submit() {
      this.isLoading = true;

      if (!this.$refs.form.validate()) {
        this.isLoading = false;
        this.$toast.error('Verifique os campos e tente novamente');
        return;
      }

      try {
        const campaignData = await this.prepareCampaignData();
        if (this.populateWith.id) {
          await CampaignService.updateCampaign(campaignData);
          this.$toast.success('Campanha atualizada com sucesso');
        } else {
          await CampaignService.createCampaign(campaignData);
          this.$toast.success('Campanha cadastrada com sucesso');
        }

        this.$emit('update-list');
        this.resetForm();
        this.close();
      } catch (e) {
        this.$handleHttpError(e);
      } finally {
        this.isLoading = false;
      }
    },

    async prepareCampaignData() {
      let { imageUrl } = this.campaign;

      if (this.imagesUpload) {
        try {
          const { link } = await createFeaturedPhoto(this.imagesUpload);
          imageUrl = link;
        } catch (error) {
          this.$handleHttpError(error);
          console.error('Erro ao fazer upload da imagem:', error);
          throw new Error('Falha ao fazer upload da imagem. O tamanho da imagem deve ser 1024x768.');
        }
      }

      const campaignData = {
        title: this.campaign.title,
        link: this.campaign.link,
        initialDate: dayjs(this.campaign.initialDate).toISOString(),
        endDate: dayjs(this.campaign.endDate).toISOString(),
        active: this.campaign.active,
        text: this.campaign.text,
        imageUrl,
        targetProd: this.campaign.targetProd.map((prod) => ({
          id: prod.id,
          title: prod.title,
          productCode: prod.productCode,
        })),
      };

      if (this.populateWith && this.populateWith.id) {
        campaignData.id = this.populateWith.id;
      }

      return campaignData;
    },

    resetForm() {
      this.$refs.form.reset();
      this.campaign = {
        active: false,
        title: '',
        link: '',
        initialDate: '',
        endDate: '',
        text: '',
        targetProd: [],
        imageUrl: null,
      };
    },
  },
};
</script>
